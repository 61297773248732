import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
// // for HttpClient import:
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
// // for Router import:
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
// // for Core import:
import { LoadingBarModule } from "@ngx-loading-bar/core";

import { CookieService } from "ngx-cookie-service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from "./app.component";
import { SignupComponent } from "./auth/signup/signup.component";

import { OverlayModule } from "@angular/cdk/overlay";
import { AppButtonComponent } from "./shared/components/auth/app-button/app-button.component";
import { AppInputComponent } from "./shared/components/auth/app-input/app-input.component";
import { BrandLogoComponent } from "./shared/components/auth/brand-logo/brand-logo.component";
import { SigninComponent } from "./auth/signin/signin.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { VerifySignupComponent } from "./auth/verify-signup/verify-signup.component";
import { EmailSelectedComponent } from "./auth/email-selected/email-selected.component";
import {AuthService} from "./auth/services/auth.service";
import { CommonComponentsModule } from "./components/common/common-components.module";
import { NgApexchartsModule } from "ng-apexcharts";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    NgApexchartsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    CommonComponentsModule
  ],
  providers: [CookieService,
    AuthService
  ],
  bootstrap: [AppComponent],
  exports:[
    CommonComponentsModule
  ]
})
export class AppModule { }
