import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-left-header',
  templateUrl: './dashboard-left-header.component.html',
  styleUrl: './dashboard-left-header.component.scss'
})
export class DashboardLeftHeaderComponent {
  @Input() tabname?: string;
  @Input() listingCount?: number;
  @Input() listingName?: string;
}
