import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { DataService } from '../../shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private data: DataService) { }

  signUp(data: any): Observable<any> {
    return this.data.post('api/app/Auth/signup', data);
  }

  signIn(data: any): Observable<any> {
    return this.data.post('api/app/Auth/signin', data);
  }

  resetPassword(data: any): Observable<any> {
    return this.data.post('api/app/Auth/reset-password', data);
  }
}
