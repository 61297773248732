import { Routes } from "@angular/router";


export const content: Routes = [
  {
    path: "simple-page",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  },
  {
    path: "single-page",
    loadChildren: () => import("../../components/single-page/single-page.module").then((m) => m.SinglePageModule),
  },
  {
    path: 'seller',
    loadChildren: () => import('../../pages/seller/seller.module').then(m => m.SellerModule)
  },
  {
    path: 'buyer',
    loadChildren: () => import('../../pages/buyer/buyer.module').then(m => m.BuyerModule)
  },
];

