import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-balance-and-stats-card',
  templateUrl: './balance-and-stats-card.component.html',
  styleUrl: './balance-and-stats-card.component.scss'
})
export class BalanceAndStatsCardComponent {
  @Input() name?: string;
  @Input() message?: string;
  @Input() content?: string;
  @Input() image?: any;
}
