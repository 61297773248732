<div class="tab-content__pane d-flex flex-column flex-md-row flex-lg-row" id="grid-layout">
    <div class="content px-0">
        <div class="row g-3 ">
            <div class="col">
                <div class="card mt-3 rounded-3">

                    <div class="card-img-top p-2">
                        <img [src]="carImage" alt="{{carName}}" class="img-fluid rounded" width="100%">
                    </div>


                    <div class="card-body border-bottom d-flex align-items-center justify-content-around p-3">
                        <div class="ongoing">
                            <small class="text-secondary">{{label}}</small>
                            <h5 class="mt-2">{{carName}}</h5>
                            <div class="d-flex align-items-center">
                                <img src="/assets/images/seller-dashboard/car-logo.png">&nbsp;&nbsp;{{year}}
                            </div>
                        </div>
                        <!-- <button class="btn px-2 me-1 mb-3">
                            &#xf107;
                        </button> -->
                        <app-button bgColor="#4ab3f4" icon="&#xf107;" width="36px" height="35px" padding="0"
                            (toggleCollapse)="toggleCarCardCollapse()"></app-button>
                    </div>

                    <!-- Collapsible Content -->
                    <div id="collapseOne" class="collapse-section collapse in " [ngClass]="{'collapse': isCollapsed}"
                        [class.show]="isCollapsed">
                        <div class="car-card-bottom">
                            <div class="row p-3">
                                <div class="col-12 col-md-6">
                                    <app-car-data carCardLabel="OWNED BY" carCardData="KHALID SAIED"
                                        [iconSrc]="'/assets/images/seller-dashboard/car-key.png'"></app-car-data>
                                </div>
                                <div class="col-12 col-md-6">
                                    <app-car-data carCardLabel="PROMOTER" carCardData="KHALID SAIED"
                                        [iconSrc]="'/assets/images/seller-dashboard/promoter.png'"></app-car-data>
                                </div>
                            </div>

                            <div class="row p-3">
                                <div class="col-12 col-md-6">
                                    <app-car-data carCardLabel="HIGHEST BID" carCardData="KHALIDD SAIED"
                                        [iconSrc]="'/assets/images/seller-dashboard/auction.png'"></app-car-data>
                                </div>
                                <div class="col-12 col-md-6">
                                    <app-car-data carCardLabel="NUMBER OF BIDS" carCardData="122"
                                        [iconSrc]="'/assets/images/seller-dashboard/tick.png'"></app-car-data>
                                </div>
                            </div>

                            <div class="row p-3">
                                <div class="col-12 col-md-6">
                                    <app-car-data carCardLabel="BID TARGET" carCardData="393800"
                                        [iconSrc]="'/assets/images/seller-dashboard/bidtarget.png'"></app-car-data>
                                </div>
                                <div class="col-12 col-md-6">
                                    <app-car-data carCardLabel="BUYING PRICE" carCardData="393800"
                                        [iconSrc]="'/assets/images/seller-dashboard/buy.png'"></app-car-data>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Footer Section -->
                    <div class="card-footer text-center">
                        <app-timer></app-timer>
                        <div class="col d-flex justify-content-center" class="car-card-bottom" id="collapseOne"
                            [ngClass]="{'collapse': isCollapsed}" [class.show]="isCollapsed">
                            <app-button bgColor="#565656" width="48px" height="50px" padding="0"
                                iconImg="/assets/images/seller-dashboard/pencil.png"></app-button>
                            <app-button label="VIEW BIDS" bgColor="#4AB3F4" width="10rem" margin="0 20px"
                                height="50px"></app-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>