import { Component } from '@angular/core';

@Component({
  selector: 'app-latest-sale-card',
  templateUrl: './latest-sale-card.component.html',
  styleUrl: './latest-sale-card.component.scss'
})
export class LatestSaleCardComponent {

}
