<div class="card-data d-flex align-items-center">
    <div><img [src]="iconSrc" alt="{{ carCardLabel }}"></div>
    <div>
        <label>{{carCardLabel}}</label>
        <h6 class="d-flex align-items-center">
            <div class="bg-success rounded-circle border border-white me-2"
                *ngIf="carCardLabel === 'OWNED BY' || carCardLabel === 'PROMOTER'"
                style="width: 0.70rem; height: 0.70rem;">
            </div>
            {{carCardData}}
            <div *ngIf="carCardLabel === 'OWNED BY'">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-check w-3 h-3 text-white">
                    <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
            </div>
        </h6>
    </div>
</div>