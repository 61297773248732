import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-car-details',
  templateUrl: './car-details.component.html',
  styleUrl: './car-details.component.scss'
})
export class CarDetailsComponent {
  @Input() label?: string;
  @Input() carName?: string;
  @Input() carImage?: any;
  @Input() year?: any;
  isCollapsed = false;
  @Output() toggleCollapse: EventEmitter<boolean> = new EventEmitter();



  toggleCarCardCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }


  onButtonToggleCollapse() {
    this.toggleCollapse.emit(true);
    console.log('Button collapse event forwarded...');
  }
}
