import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-car-data',
  templateUrl: './car-data.component.html',
  styleUrl: './car-data.component.scss'
})
export class CarDataComponent {
  @Input() carCardLabel?: string;
  @Input() carCardData?: string;
  @Input() iconSrc?: string;
}
