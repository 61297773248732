<div class="row latest-card">
    <div class="col-12 col-md-10 col-lg-10">
        <div class="d-flex">
            <div>Latest Sale</div>
            <div>$3,000</div>
        </div>
        <div class="latest-card-content">Toyota Camry 2019 sold to <b>Jessica Albertio</b> on <b>10TH JAN 2020</b></div>
    </div>

    <div class="col-12 col-md-2 col-lg-2">
        <app-button [iconImg]="'assets/images/seller-dashboard/arrow-16.png'" width="40px" height="128px"
            bgColor="#4AB3F4" padding="0">
        </app-button>
    </div>
</div>