import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// common components
import { CarDataComponent } from './car-data/car-data.component';
// import { TabsComponent } from './tabs/tabs.component';
import { TimerComponent } from './timer/timer.component';
import { DashboardLeftHeaderComponent } from './dashboard-left-header/dashboard-left-header.component';
import { CarDetailsComponent } from './car-details/car-details.component';
import { CarCardDataComponent } from './car-card-data/car-card-data.component';
import { ButtonComponent } from './button/button.component';
import { CarCardGridLayoutComponent } from './car-card-grid-layout/car-card-grid-layout.component';
import { BalanceAndStatsCardComponent } from './balance-and-stats-card/balance-and-stats-card.component'
import { LatestSaleCardComponent } from './latest-sale-card/latest-sale-card.component'

@NgModule({
  declarations: [
    CarDataComponent,
    // TabsComponent,
    TimerComponent,
    DashboardLeftHeaderComponent,
    CarDetailsComponent,
    CarCardDataComponent,
    ButtonComponent,
    CarCardGridLayoutComponent,
    BalanceAndStatsCardComponent,
    LatestSaleCardComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CarDataComponent,
    // TabsComponent,
    TimerComponent,
    DashboardLeftHeaderComponent,
    CarDetailsComponent,
    CarCardDataComponent,
    ButtonComponent,
    CarCardGridLayoutComponent,
    BalanceAndStatsCardComponent,
    LatestSaleCardComponent
  ]
})
export class CommonComponentsModule { }
