import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-car-card-data',
  templateUrl: './car-card-data.component.html',
  styleUrl: './car-card-data.component.scss'
})
export class CarCardDataComponent {
  @Input() label?: string;
  @Input() labelData?: string;
}
