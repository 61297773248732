<div class="media profile-media">
  <img class="b-r-50" src="assets/images/dashboard/profile.png" alt="">
  <div class="media-body"><span>
      APURVA RAUT</span>
    <!-- <p class="mb-0 font-roboto">Admin <i class="middle fa fa-angle-down"></i></p> -->
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li class="profile-top pt-3">
    <span>APURVA RAUT</span><br>
    <label class="f-12">DevOps Engineer</label>
  </li>
  <li><a href="javascript:void(0)"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Profile </span></a></li>
  <li><a href="javascript:void(0)"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Add Account</span></a>
  </li>
  <li><a href="javascript:void(0)"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Reset
        Password</span></a></li>
  <li><a href="javascript:void(0)"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Help</span></a>
  </li>
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span>
  </li>
</ul>