import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrl: './timer.component.scss'
})
export class TimerComponent {
  @Output() toggleCollapse: EventEmitter<boolean> = new EventEmitter();

  onToggleCollapse() {
    this.toggleCollapse.emit(true);
    console.log("clicked..........")
  }
}
