import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient
  ) {
  }

  private static formatErrors(error: any) {
    return throwError(error);
  }

  get(path: string, params: any = {}): Observable<any> {
    return this.http.get(`${environment.apiURL}${path}`, { params })
      .pipe(catchError(DataService.formatErrors));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(`${environment.apiURL}${path}`, body)
      .pipe(catchError(DataService.formatErrors));
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http.post(`${environment.apiURL}${path}`, body)
      .pipe(catchError(DataService.formatErrors));
  }

  delete(path, params?: any): Observable<any> {
    return this.http.delete(`${environment.apiURL}${path}`, { params })
      .pipe(catchError(DataService.formatErrors));
  }

  downloadCSV(path: string, filename: string, params?: any): Observable<any> {
    return this.http.get(`${environment.apiURL}${path}`, { params, responseType: 'arraybuffer' })
      .pipe(map((response: any) => {
        const file = new Blob([response], { type: 'text/csv' });

        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = filename + '.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }));
  }

}
