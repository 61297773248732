<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" />
        </a>
      </div>
      <!-- <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div> -->
    </div>
    <div class="left-header col-xxl-5 col-xl-6 col-lg-3 col-md-4 col-sm-3 p-0">
      <!-- <app-swiper></app-swiper> -->
      <img src="\assets\images\layout-images\marvin-logo.png">
    </div>
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">
        <li class="language-nav menus">
          EXPLORE NEW LISTING
        </li>
        <li class="language-nav menus">
          FAQS
        </li>
        <li class="language-nav menus">
          CONTACT US

        </li>

        <li class="wallet">
          <img src="\assets\images\layout-images\Group893.png"> $ 2,350,00
        </li>
        <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>

        <li class="question-mark">
          ?
        </li>
        <li class="search-sm">
          <span class="header-search" (click)="searchToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#search"></use>
            </svg>
          </span>
        </li>
        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li>
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>

        <li class="toggle">
          <div class="toggle-sidebar" (click)="sidebarToggle()">
            <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row header-tab-search-btn">
    <ul class="col-10 header-tab">
      <li *ngFor="let item of menuItems; let i = index" (click)="setActiveTab(i)"
        [ngClass]="{'active': activeTab === i}" routerLinkActive="active">
        <a class="menu" [routerLink]="item.url">
          <div class="menu__icon"></div>
          <div class="menu__title">
            {{ item.name }}
          </div>
        </a>
      </li>
    </ul>

    <div class="col-4 search sm:block search-container">
      <input type="text" class="mt-1 search__input input placeholder-theme-13 forsearch" placeholder="Search...">
      <i data-feather="search" class="search__icon dark:text-gray-300"></i>
    </div>
    <div class="col car-request-btn">
      <div class="w-full sm:w-auto flex pt-1 sm:mt-0 Fnt-family text-xs ">
        <a data-toggle="modal" data-target="#superlarge-modal-size-preview" class="button button--xl h-14 w-full bg-theme-33
                 brder-radius-2rem flex items-center justify-center
                                  text-white text-xs">
          MAKE A CAR REQUEST
        </a>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>
<!-- Page Header Ends -->