import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() label: string = '';
  @Input() btnClass: string = '';
  @Input() width: string = 'auto';
  @Input() height: string = 'auto';
  @Input() color: string = 'color';
  @Input() bgColor: string = '';
  @Input() margin: string = '';
  @Input() padding: string = '';
  @Input() iconImg: any;
  @Input() icon: any;
  @Output() clicked = new EventEmitter<void>();

  handleClick() {
    this.clicked.emit();
  }

  @Output() toggleCollapse: EventEmitter<boolean> = new EventEmitter();

  onToggleCollapse() {
    this.toggleCollapse.emit(true);
    console.log("clicked..........")
  }
}
