<div class=" align-items-start car-details-common">
    <img [src]="carImage" alt="{{ carName }}" class="car-image">

    <div class="ongoing">
        <div class="d-flex justify-content-around">
            <div>
                <label>{{label}}</label>
                <h5>{{carName}}</h5>
                <label><img src="/assets/images/seller-dashboard/car-logo.png">&nbsp;&nbsp;{{year}}</label>
            </div>
            <div class=" collapse-btn-sm">
                <app-button bgColor="#4ab3f4" icon="&#xf107;" width="36px" height="35px" padding="0"
                    (toggleCollapse)="onButtonToggleCollapse()"></app-button>
            </div>
        </div>
    </div>
</div>