import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-common-button',
  templateUrl: './common-button.component.html',
  styleUrls: ['./common-button.component.scss']
})
export class CommonButtonComponent {
  @Input() label: string = '';
  @Input() btnClass: string = '';
  @Input() width: string = 'auto';
  @Input() height: string = 'auto';
  @Input() color: string = 'color';
  @Input() bgColor: string = '';
  @Input() margin: string = '';

  @Output() clicked = new EventEmitter<void>();

  handleClick() {
    this.clicked.emit();
  }
}
