import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-car-card-grid-layout',
  templateUrl: './car-card-grid-layout.component.html',
  styleUrl: './car-card-grid-layout.component.scss'
})
export class CarCardGridLayoutComponent {
  @Input() carImage?: any;
  @Input() label?: string;
  @Input() carName?: string;
  @Input() year?: string;


  isCollapsed = false;
  toggleCarCardCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

}
