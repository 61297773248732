<div class="notification-box" (click)="toggleNotificationMobile()">
  <!-- <svg>
    <use href="assets/svg/icon-sprite.svg#notification"></use>
  </svg>
  <span class="badge rounded-pill badge-secondary">4</span> -->
  <img src="\assets\images\layout-images\white-bell.png">
</div>
<div class="onhover-show-div notification-dropdown">
  <div class="d-flex justify-content-between notify-top">
    <h6 class="f-14 mb-0  dropdown-title"><i class="fa-solid fa-bell f-18"
        style="color: #383838;"></i>&nbsp;&nbsp;&nbsp;Notifications <span> (4)</span>
    </h6>
    <label class="font-medium ">Mark all as read</label>
  </div>
  <ul>
    <li class="b-l-primary border-4">
      <p>Delivery processing <span class="font-danger">10 min.</span></p>
    </li>
    <li class="b-l-success border-4">
      <p>Order Complete<span class="font-success">1 hr</span></p>
    </li>
    <li class="b-l-secondary border-4">
      <p>Tickets Generated<span class="font-secondary">3 hr</span></p>
    </li>
    <li class="b-l-warning border-4">
      <p>Delivery Complete<span class="font-warning">6 hr</span></p>
    </li>
    <li><a class="f-w-700" href="#">Check all</a></li>
  </ul>
</div>